/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.ion-invalid.ion-touched ion-label {
  color: --ion-color-danger;
}

:root {
  --ion-color-primary: #ffce57;
  --ion-color-primary-rgb: 255, 206, 87;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #ffac24;
  --ion-color-primary-tint: #ffac24;

  --ion-color-secondary: #ff7200;
  --ion-color-secondary-rgb: 255, 114, 0;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #b26b31;
  --ion-color-secondary-tint: #b26b31;
}

ion-content {
  &::part(background) {
    background: url(/assets/img/loginBg.jpg) no-repeat center/cover fixed;
  }
}

ion-content.nobackground {
  --background: none;
}

ion-list,
.controls-container {
  max-width: min(600px, calc(100% - 30px));
  margin-left: auto !important;
  margin-right: auto !important;
}

.login-card {
  margin-top: 40px;
  max-width: min(400px, calc(100% - 30px));
  margin-left: auto !important;
  margin-right: auto !important;
}

body:before {
  content: "";
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  background-color: rgba(255, 255, 255, 0);
}

ion-list {
  opacity: 0.9;
}

.bee {
  display: inline;
}
